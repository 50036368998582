import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axios-instance";

const TemplatesPage = () => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get("v1/configs/templates");
        // console.log("templates data:::", resp.data);
        setTemplates(res.data.waba_templates);
        console.log("Templates::", res.data.waba_templates);
      } catch (error) {
        console.log(error);
      }
    })();
    setIsLoading(false);
  }, []);

  const search = (templates) => {
    return templates.length > 0
      ? templates?.filter((item) => item.name.toLowerCase().includes(query))
      : "";
  };
  return (
    <div>
      {/* Nabvar */}
      <div className="flex  justify-between px-[20px] items-center h-[80px] border-b border-white border-opacity-20 shadow-lg w-full ">
        <h1 className="text-lg font-semibold text-white/80">Templates</h1>
        {/* -----------------------------------------SEARCH---------------------------------------- */}
        <div className="flex items-center  w-[50%] px-[50px] my-[10px]">
          <div className="rounded-tr-none rounded-br-none rounded-xl  bg-white/60 text-black/70 font-bold h-[40px] w-[100px] flex items-center justify-center">
            Search
          </div>
          <input
            type="search"
            name=""
            id=""
            className="w-full h-[40px]  text-black  bg-white/40 focus:bg-white/80 focus:outline-none 
            focus:ring-1 rounded-xl px-2 py-1 placeholder:text-white/80 focus:placeholder:text-gray-500 
            transition-all duration-300 ease-in-out focus:ring-[#FED500]  rounded-tl-none rounded-bl-none"
            placeholder="Search by Name..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      {/* Body */}

      <div>
        {isLoading ? (
          <div>
            <h1>Loading...</h1>
          </div>
        ) : (
          <div className="flex justify-center w-full p-[40px] pb-10 overflow-y-auto h-[calc(100vh-80px)] scrollbar-track-transparent">
            <div className="flex flex-col gap-8 w-[80%]">
              {search(templates).length > 0 ? (
                search(templates)?.map((template, index) => {
                  return (
                    <div
                      key={index}
                      className="flex  glassed text-white p-5 rounded-xl"
                    >
                      <div className="flex w-[50%] flex-col gap-2">
                        <div className="flex items-center justify-between w-full">
                          <h1>
                            Name:{" "}
                            <span className="text-white/70">
                              {template.name}
                            </span>
                          </h1>
                        </div>
                        <div className="mt-[-10px]">
                          <h1>
                            Language:{" "}
                            <span className="text-white/70">
                              {template.language}
                            </span>
                          </h1>
                        </div>
                        <div>
                          <h1>
                            Namespace:{" "}
                            <span className="text-white/70">
                              {template.namespace}
                            </span>
                          </h1>
                        </div>
                        <div>
                          <h1>
                            Rejected Reason:{" "}
                            <span className="text-white/70">
                              {template.rejected_reason}
                            </span>
                          </h1>
                        </div>
                        <div>
                          <h1>
                            Category:{" "}
                            <span className="text-white/70">
                              {template.category}
                            </span>
                          </h1>
                        </div>
                        <div
                          className={`${
                            template.status === "rejected"
                              ? "bg-red-200 text-red-600"
                              : "bg-green-300 text-green-700"
                          } font-black rounded p-2 w-[200px] flex justify-center items-center`}
                        >
                          Status:
                          <span className="capitalize  font-bold">
                            {template.status}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col w-[50%] gap-2  items-center">
                        <div className="flex">
                          {template.components.length > 0
                            ? template.components
                                .filter((item) => item.type === "BODY")
                                .map((item, i) => {
                                  console.log(item);
                                  return (
                                    <div
                                      key={i}
                                      className="glassed w-full rounded-lg p-4 flex flex-col items-center gap-2"
                                    >
                                      <h1 className="text-white text-2xl font-bold">
                                        Content
                                      </h1>
                                      <p className="text-sm text-white/80 text-justify">
                                        {item.text}
                                      </p>
                                    </div>
                                  );
                                })
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-white/70 flex w-full justify-center items-center h-full  text-3xl">
                  <h1>No results found!</h1>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplatesPage;
