import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { axiosInstance } from "../../utils/axios-instance";
import Autocomplete from "@mui/material/Autocomplete";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ChangeLead = ({ number, oldUsername }) => {
  const login = useSelector((state) => state.login);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    console.log(login);
    if (login.access_token) {
      console.log(login);
      // const jDecode = jwtDecode(login.accessToken);
      if (login.isAdmin === true) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [login]);

  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState([]);
  const [isLeadAssigned, setIsLeadAssigned] = useState(false);
  const [data, setData] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData("");
    setOpen(false);
  };

  const fetchUserName = async () => {
    const response = await axiosInstance.get("get_users");
    console.log(response.data.user_id);
    let list = [];
    response?.data?.user_id.forEach((item) => {
      list.push(item.username);
    });
    setUsername(list);
  };
  useEffect(() => {
    fetchUserName();
  }, []);

  const handleSubmit = async () => {
    console.log(data);
    let formData = new FormData();
    formData.append("old_username", oldUsername);
    formData.append("number", number);
    formData.append("new_username", data);
    try {
      await axiosInstance.post("admin/change_lead", formData);
      setIsLeadAssigned(true);
      handleClose()
    } catch (error) {
      console.log("Error in Assigning Leads", error);
    } finally {
      setTimeout(() => {
        setIsLeadAssigned(false);
      }, 6000);
    }
  };
  return (
    <>
      {isAdmin ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isLeadAssigned}
            autoHideDuration={6000}
            onClose={() => setIsLeadAssigned(false)}
          >
            <Alert onClose={() => setIsLeadAssigned(false)} severity="success">
              Lead assigned successfully!
            </Alert>
          </Snackbar>

          <Autocomplete
            disablePortal
            clearIcon=""
            disableClearable={true}
            id="combo-box-demo"
            options={username}
            sx={{ width: 200 }}
            value={data}
            onChange={async (event, newValue) => {
              setData(newValue);
              handleClickOpen();
            }}
            className="!text-xs"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Operator"
                // fullWidth
                size="small"
                className="!text-xs"
              />
            )}
          />
          <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Change Lead`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Do you really want to change the lead of this ${number} from ${oldUsername} to ${data}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Discard</Button>
              <Button onClick={handleSubmit} autoFocus>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

export default ChangeLead;
