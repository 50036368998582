import React from "react";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div
      className={classNames(
        classes.pageTitleContainer,
        "px-[30px] shadow-xl border-b border-white border-opacity-10 h-[50px] flex items-center w-full",
      )}
    >
      <h1 className="text-sm font-bold text-white opacity-80">
        {props.title}
      </h1>
      {props.button && props.button}
    </div>
  );
}
