import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Box,
  TextField,
  InputLabel,
  Divider,
  MuiThemeProvider,
  createMuiTheme,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/styles";
import SendIcon from "@material-ui/icons/Send";
import {
  CloudUpload,
  Description,
  Email,
  Visibility,
  VisibilityOff,
  VpnKey,
} from "@material-ui/icons";
// styles
import useStyles from "./styles";
import ImageShowCase from "../../images/image-column.PNG";
import AudioShowCase from "../../images/audio.PNG";
import VideoShowCase from "../../images/video-column.PNG";
import PhoneShowCase from "../../images/phone-number.PNG";
// components
import mock from "./mock";
import Widget from "../../components/Widget";
// import TextField from '@mui/material/TextField';
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Table from "./components/Table/Table";
import Modal from "../../components/Modal";
import { useDispatch, useStore, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useRouteMatch } from "react-router-dom";
import { timeConverter } from "../../utils/date-parse";
import {
  downloadHistoryData,
  downloadUserHistoryData,
  getUsers,
  updateUserPassword,
} from "../../store/reducer/users";
import Alert from "@material-ui/lab/Alert";
import { axiosInstance } from "../../utils/axios-instance";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PhoneNumberStep = ({ onChangeNumber, number, classes }) => {
  return (
    <div>
      <Typography size={"sm"}>Enter phone number</Typography>
      <PhoneInput
        style={{ marginTop: "5px" }}
        international
        onCountryChange={(countryCode) => {
          console.log(countryCode);
        }}
        defaultCountry="IN"
        autoComplete="on"
        value={number}
        className={classes.phoneNumberInput}
        countryCallingCodeEditable={false}
        onChange={onChangeNumber}
        error={
          number
            ? isValidPhoneNumber(number)
              ? undefined
              : "Invalid Phone Number"
            : ``
        }
      />
    </div>
  );
};

const ChangePasswordStep = ({
  onChangePassword,
  password,
  email,
  classes,
  onEyeClick,
  passwordVisible,
}) => {
  return (
    <div>
      <TextField
        fullWidth
        variant={"outlined"}
        disabled
        value={email}
        label="User Email"
        type="email"
        margin={"normal"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
        }}
        id="email"
      />
      <br />

      <TextField
        fullWidth
        label="Enter New Password"
        type={passwordVisible ? "text" : "password"}
        margin={"normal"}
        variant={"outlined"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKey />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={onEyeClick}>
                {passwordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={password}
        onChange={onChangePassword}
      />
    </div>
  );
};

export default function Users(props) {
  const [isUserAdded, setIsUserAdded] = useState(false);
  const [addUserData, setAddUserData] = useState({
    username: "",
    password: "",
  });
  var classes = useStyles();
  var theme = useTheme();
  const dispatch = useDispatch();
  const store = useStore();
  const userList = useSelector((state) => state.users.usersList);

  const error = useSelector((state) => state.users.error);
  const chatHistoryDownloaded = useSelector(
    (state) => state.users.chatHistoryDownloaded,
  );
  const passwordUpdated = useSelector((state) => state.users.passwordUpdated);

  //loader for sending request
  const loading = useSelector((state) => state.users.loading);

  // !delete user dialog
  const [openDEL, setOpenDEL] = React.useState(false);
  const [isUserDel, setIsUserDel] = useState(false);

  // useEffect for get users
  useEffect(() => {
    dispatch(getUsers());
  }, [isUserAdded, isUserDel]);

  // open chat modal state
  const [openChatModal, setOpenChatModal] = useState(false);
  const [currentChatModalStepIndex, setChatCurrentModalStepIndex] = useState(0);
  const [contactNumber, setContactNumber] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const [editUserModal, setEditUserModal] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  const onChangeNumber = (value) => {
    setContactNumber(value);
  };
  const ChatHistoryModal = [
    <PhoneNumberStep
      number={contactNumber}
      onChangeNumber={onChangeNumber}
      classes={classes}
    />,
  ];
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onEyeClick = () => {
    setPasswordVisible(!passwordVisible);
  };
  const EditChatModal = [
    <ChangePasswordStep
      onEyeClick={onEyeClick}
      passwordVisible={passwordVisible}
      password={password}
      email={currentUser}
      onChangePassword={onChangePassword}
      classes={classes}
    />,
  ];
  useEffect(() => {
    if (chatHistoryDownloaded) {
      setCurrentUser("");
      setOpenChatModal(false);
      setContactNumber("");
    }
  }, [chatHistoryDownloaded]);

  useEffect(() => {
    if (passwordUpdated) {
      setCurrentUser("");
      setEditUserModal(false);
      setPassword("");
      setPasswordVisible(false);
      setShowSnackBar(true);
    }
  }, [passwordUpdated]);

  const onDownloadHistory = (user) => {
    if (user != "" && user != undefined) {
      var formData = new FormData();
      formData.append("user", user);
      dispatch(downloadHistoryData(formData));
    }
  };

  const onEditUser = (user) => {
    setCurrentUser(user);
    setEditUserModal(true);
  };
  // ! -------------------------delete---user-------------------
  const handleClickOpenDEL = () => {
    setOpenDEL(true);
  };

  const handleCloseDEL = () => {
    setOpenDEL(false);
    setCurrentUser("");
  };
  const onDeleteUser = (user) => {
    setCurrentUser(user);
    handleClickOpenDEL();
    // setEditUserModal(true);
  };

  const confirmDeleteUser = async () => {
    // currentUser
    let data = new FormData();
    data.append("username", currentUser);
    try {
      await axiosInstance.post("del_user", data);
      setIsUserDel(true);
      setCurrentUser("");
      handleCloseDEL();
    } catch (error) {
      console.log("Error in deleting the user::", error);
    } finally {
      setTimeout(() => {
        setIsUserDel(false);
        setCurrentUser("");
      }, 6000);
    }
  };
  // ! -------------------------delete---user-------------------

  const onEditUserNextClick = () => {
    if (currentUser != "" && password !== "") {
      let formData = new FormData();
      formData.append("password", password);
      formData.append("user", currentUser);
      dispatch(updateUserPassword(formData));
    }
  };
  const onEditUserCancelClick = () => {
    setCurrentUser("");
    setEditUserModal(false);
    setPasswordVisible(false);
    setPassword("");
  };

  const onChatModalNextClick = () => {
    if (currentUser != "" && contactNumber != "") {
      var formData = new FormData();
      let cNumber = contactNumber.slice(1, contactNumber.length);
      formData.append("phone", cNumber);
      formData.append("user", currentUser);
      dispatch(downloadUserHistoryData(formData));
    }
  };
  const onChatModalCancelClick = () => {
    setCurrentUser("");
    setOpenChatModal(false);
    setContactNumber("");
  };

  const onDownloadUserHistory = (user) => {
    setCurrentUser(user);
    setOpenChatModal(true);
    setChatCurrentModalStepIndex(0);
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const handleChangeAddUser = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddUserData({ ...addUserData, [name]: value });
  };
  const handleAddUser = async (e) => {
    e.preventDefault();
    console.log(addUserData);
    let data = new FormData();
    data.append("username", addUserData.username);
    data.append("password", addUserData.password);
    try {
      await axiosInstance.post("add_user", data);
      setIsUserAdded(true);
      setAddUserData({
        username: "",
        password: "",
      });
    } catch (error) {
      console.log("Error in adding new user:: ", error);
    } finally {
      setTimeout(() => {
        setIsUserAdded(false);
      }, 7000);
    }
  };
  return (
    <section className="h-screen overflow-y-auto">
      <Box className="relative">
        <PageTitle
          title="Users Management"
          // button="Add User"
          // handleAddUser={handleAddUser}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackBar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Password updated successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isUserAdded}
          autoHideDuration={5000}
          onClose={() => setIsUserAdded(false)}
        >
          <Alert onClose={() => setIsUserAdded(false)} severity="success">
            User Added successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isUserDel}
          autoHideDuration={5000}
          onClose={() => setIsUserDel(false)}
        >
          <Alert onClose={() => setIsUserDel(false)} severity="success">
            User Deleted successfully!
          </Alert>
        </Snackbar>

        {/*download chat modal*/}
        <Modal
          title={"Download Chat History"}
          onNext={onChatModalNextClick}
          onCancel={onChatModalCancelClick}
          loading={loading}
          error={error}
          theme={theme}
          open={openChatModal}
        >
          {ChatHistoryModal[currentChatModalStepIndex]}
        </Modal>
        {/*edit user modal*/}
        <Modal
          title={"Edit user details"}
          onNext={onEditUserNextClick}
          onCancel={onEditUserCancelClick}
          loading={loading}
          error={error}
          theme={theme}
          open={editUserModal}
        >
          {EditChatModal[0]}
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleAddUser}>
              <div className="p-5 flex gap-2 glassed">
                <TextField
                  // id="outlined-basic"
                  label="Username"
                  variant="filled"
                  type="email"
                  size="small"
                  className="!text-white placeholder:text-white bg-white rounded-lg"
                  name="username"
                  required
                  value={addUserData.username}
                  onChange={handleChangeAddUser}
                />
                <TextField
                  // id="outlined-basic"
                  label="Password"
                  variant="filled"
                  type="password"
                  size="small"
                  name="password"
                  className="!text-white placeholder:text-white bg-white rounded-lg"
                  autoComplete="off"
                  required
                  value={addUserData.password}
                  onChange={handleChangeAddUser}
                />
                <Button
                  variant="contained"
                  type="submit"
                  className="bg-[#ffdc2b] text-black text-xs font-bold  rounded-lg px-4"
                >
                  Add User
                </Button>
              </div>
            </form>
            {/* <Widget
            title="User List"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            </Widget> */}
            <Table
              data={userList}
              onEditUser={onEditUser}
              onDeleteUser={onDeleteUser}
              onDownloadHistory={onDownloadHistory}
              onDownloadUserHistory={onDownloadUserHistory}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openDEL}
        onClose={handleCloseDEL}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete {currentUser} user!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDEL}>Disagree</Button>
          <Button onClick={confirmDeleteUser} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}
