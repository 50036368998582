import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../../utils/axios-instance';

const ScheduledJobs = () => {
  const [jobsData, setJobsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    let data = new FormData();
    data.append("number", "");
    data.append("limit", "20");
    data.append("status", "Scheduled");
    const result = await axiosInstance.post("filter/alljobs", data);
    setJobsData(result);
    setIsLoading(false);
    console.log(jobsData);
  };
  
  useEffect(() => {
    fetchData();
    console.log(jobsData);
  }, []);
  console.log(jobsData);
  return (
    <div>ScheduledJobs</div>
  )
}

export default ScheduledJobs