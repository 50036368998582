import React, { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../../store/reducer/chat";
import { axiosInstance } from "../../utils/axios-instance";
import Header from "./components/Header";

import ContactTable from "./components/Table/ContactTable";
import ContactTableNew from "./components/Table/ContactTableNew";

export default function Contacts() {
  const dispatch = useDispatch();
  const [profileVar, setProfileVar] = useState({});

  const contacts = useSelector((state) => state.chat.contacts);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  useEffect(() => {
    const fetchVar = async () => {
      try {
        var data = new FormData();
        data.append("number", contacts.number);
        const res = await axiosInstance.post("get_profile_variables", data);
        setProfileVar(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchVar();
  }, []);

  return (
    <div>
      <Header />
      <div className="h-[calc(100vh-80px)] overflow-y-auto pb-10">
        <ContactTableNew itemsPerPage={50} />
      </div>
    </div>
  );
}
