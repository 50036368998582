import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Button, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { axiosInstance } from "../../../utils/axios-instance";
import Checkbox from "@mui/material/Checkbox";
// import ChangeLead from "../../../components/admin/ChangeLead";
import AssignNewLeads from "../../../components/admin/AssignNewLeads";

const AdminControls = () => {
  const [data360, setData360] = useState({
    api_key: "",
    namespace: "",
  });
  const [leads, setLeads] = useState(false);
  const [isAPIChanged, setIsAPIChanged] = useState(false);
  const handleChange360 = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData360({ ...data360, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData();
    console.log(data360);
    console.log(leads);
    data.append("api_key", data360.api_key);
    data.append("namespace", data360.namespace);
    data.append("auto_assign_leads", leads);
    try {
      await axiosInstance.post("change_api", data);
      setIsAPIChanged(true);
      setData360({
        api_key: "",
        namespace: "",
        // lead: true,
      });
    } catch (error) {
      console.log("Error on Changing 360 API ::", error);
    } finally {
      setTimeout(() => {
        setIsAPIChanged(false);
      }, 7000);
    }
  };
  return (
    <section>
      <div className="flex w-full items-center h-[50px] border-b border-white/20 text-white font-bold px-10 ">
        <div>Admin Controls</div>
      </div>
      <div className="h-[calc(100vh-50px)] overflow-y-auto pt-5 px-10">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isAPIChanged}
          autoHideDuration={6000}
          onClose={() => setIsAPIChanged(false)}
        >
          <Alert onClose={() => setIsAPIChanged(false)} severity="success">
            360 API changed successfully!
          </Alert>
        </Snackbar>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 items-start justify-start "
        >
          <div className="flex items-center gap-2">
            <TextField
              // id="outlined-basic"
              label="Api Key"
              variant="filled"
              type="text"
              size="small"
              className="!text-white placeholder:text-white bg-white rounded-lg w-[250px]"
              name="api_key"
              // required
              value={data360.username}
              onChange={handleChange360}
            />
            <TextField
              // id="outlined-basic"
              label="Namespace"
              variant="filled"
              type="text"
              size="small"
              className="!text-white placeholder:text-white bg-white rounded-lg w-[250px]"
              name="namespace"
              // required
              value={data360.namespace}
              onChange={handleChange360}
            />
          </div>
          <div className="flex items-center gap-2 text-white/80">
            <Checkbox
              name="lead"
              // value={}
              onChange={(e) => {
                console.log(e.target.checked);
                setLeads(e.target.checked);
              }}
              className="!border !text-white  !border-white"
            />
            <h1>Auto Assign Leads</h1>
          </div>
          <Button
            variant="contained"
            type="submit"
            className="bg-[#ffdc2b] text-black text-xs font-bold  rounded-lg px-4"
          >
            Change API
          </Button>
        </form>
        {/* -----------------Assigning--Leads-Section------------- */}
        <AssignNewLeads />
      </div>
    </section>
  );
};

export default AdminControls;
