import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  Box,
  IconButton,
} from "@material-ui/core";
import useStyles from "../../styles";
import { Typography } from "../../../../components/Wrappers";
import {
  removeUnderscoreAndCapitalize,
  timeConverter,
} from "../../../../utils/date-parse";
import SendIcon from "@material-ui/icons/Send";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Edit } from "@material-ui/icons";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const states = {
  sent: "success",
  pending: "warning",
  declined: "secondary",
};

export default function TableComponent({
  data,
  onDownloadHistory,
  onDownloadUserHistory,
  onEditUser,
  onDeleteUser,
}) {
  const classes = useStyles();

  if (data.length > 0) {
    var keys = Object.keys(data[0]).map((i) =>
      removeUnderscoreAndCapitalize(i),
    );
    return (
      <Table className="mb-0 text-xs text-white">
        <TableHead>
          <TableRow className="font-bold text-white glassed">
            <TableCell className="text-xs font-bold text-white">S.No</TableCell>
            {keys.map((key) => (
              <TableCell key={key} className="text-xs font-bold text-white">
                {key}
              </TableCell>
            ))}
            <TableCell
              align={"center"}
              className="text-xs font-bold text-white"
            >
              Download Contacted List
            </TableCell>
            <TableCell
              align={"center"}
              className="text-xs font-bold text-white"
            >
              Download Chat List
            </TableCell>
            <TableCell
              align={"center"}
              className="text-xs font-bold text-white"
            >
              {" "}
              Edit User
            </TableCell>
            <TableCell
              align={"center"}
              className="text-xs font-bold text-white"
            >
              {" "}
              Delete User
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ username }, index) => (
            <TableRow key={index}>
              <TableCell className="text-white/60" align={"left"}>
                {index + 1}
              </TableCell>
              <TableCell className="text-white/60" align={"left"}>
                {username}
              </TableCell>
              <TableCell className="text-white/60" align={"center"}>
                <IconButton
                  size="medium"
                  // color="#ffdc2b"
                  onClick={() => onDownloadHistory(username)}
                  className="!text-[#ffdc2b]"
                >
                  <CloudDownloadIcon />
                </IconButton>
              </TableCell>
              <TableCell align={"center"}>
                <IconButton
                  size="medium"
                  // color="secondary"
                  onClick={() => onDownloadUserHistory(username)}
                  className="text-[#ffdc2b]"
                >
                  <CloudDownloadIcon />
                </IconButton>
              </TableCell>
              <TableCell align={"center"}>
                <IconButton
                  size="medium"
                  // color="secondary"
                  onClick={() => onEditUser(username)}
                  className="!text-[#ffdc2b]"
                >
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell align={"center"}>
                <IconButton
                  size="medium"
                  // color="secondary"
                  onClick={() => onDeleteUser(username)}
                  className="!text-[#ffdc2b]"
                >
                  <DeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  } else {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box height={"100px"} />
        <Typography className="text-lg text-white">
          You don't have any message list yet !!
        </Typography>
        <Box height={"200px"} />
      </Box>
    );
  }
}
