import React, { useState } from "react";
import {
  PencilIcon,
  TrashIcon,
  PencilAltIcon,
  PlusCircleIcon,
  ShieldCheckIcon,
  SearchIcon,
} from "@heroicons/react/solid";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import Modal from "@mui/material/Modal";
import { axiosInstance } from "../../../../utils/axios-instance";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
import moment from "moment";
import AddVariablesModal from "../Modals/AddVariablesModal";
import NotesModal from "../Modals/NotesModal";
import DeleteModal from "../Modals/DeleteModal";
import EditModal from "../Modals/EditModal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// MUI
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//? ==================>
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };
// const styleNotes = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "50%",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const ContactList = ({
  id,
  name,
  number,
  createdAt,
  variables,
  username,
  unseen,
  unseen_count,
}) => {
  const [contactData, setContactData] = useState({
    name,
    number,
  });
  const [openVarDialog, setOpenVarDialog] = useState(false);
  const handleClickOpenVarDialog = () => {
    setOpenVarDialog(true);
  };

  const handleCloseVarDialog = () => {
    setOpenVarDialog(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // delete state modal
  const [openD, setOpenD] = useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);
  // delete state modal
  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);
  // Variables Modal
  const [openVarModal, setOpenVarModal] = useState(false);
  const handleOpenVarModal = () => setOpenVarModal(true);
  const handleCloseVarModal = () => setOpenVarModal(false);

  const [isDeleted, setIsDeleted] = useState(false);

  // const date = new Date(createdAt).toLocaleDateString("en-US");
  const relDate = moment(createdAt).format("YYYYMMDD");

  const handleDelete = async (e) => {
    console.log(number);
    try {
      const result = await axiosInstance.post("delete/lead", { number });
      if (result?.data.success) setIsDeleted(true);
      console.log(result);

      setTimeout(() => {
        handleCloseD();
      }, 3000);
      handleCloseD();
    } catch (error) {
      console.log(error);
    }
  };
  // splitting variables keys and values

  const handleChange = (e) => {
    const inName = e.target.name;
    const inValue = e.target.value;
    setContactData({
      ...contactData,
      [inName]: inValue,
    });
    console.log(contactData.name, contactData.number);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("name", contactData.name);
    data.append("number", contactData.number);
    try {
      const result = await axiosInstance.post("edit_contact", data);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Variables
  const handleDeleteVariable = (userKey, userValue) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Proceed",
          onClick: async () => {
            try {
              await axiosInstance.post("del_profile_variables", {
                number,
                profile_variables: {
                  [userKey]: userValue[0],
                },
              });
            } catch (error) {
              console.log("Error in deleting Variables:: ", error);
            }
          },
        },
        {
          label: "Discard",
          onClick: () => alert("You have canceled the deletion!"),
        },
      ],
      overlayClassName: "overlay-custom-class-name",
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };

  return (
    <>
      <div className="flex glassed text-[10px]">
        {/* col-1 */}
        <div className="w-[15vw] border-r border-b flex items-center justify-start border-white/10 p-[10px]">
          <h1 className="font-bold text-start text-white/80">
            {!name ? "User" : name}
          </h1>
        </div>
        {/* col-2-------Number-------------  */}
        <div className="border-b flex items-center justify-center w-[15vw] border-r border-white/10 p-[10px]">
          <p className="text-white/70">{number}</p>
        </div>
        {/* col-3-----Status----- */}
        <div className="border-b flex items-center justify-center w-[10vw] border-r border-white/10 p-[10px]">
          <ShieldCheckIcon className="w-[20px] text-green-600 " />
        </div>
        {/* col-4:::ATTRIBUTES------- */}
        <div className="border-b flex justify-between items-start  w-[30vw] border-r border-white/10 p-[10px]">
          <div className="flex items-start w-max  p-[5px] rounded-lg glassed ">
            {variables ? (
              <>
                {/* KEY DIV */}
                <div className="font-bold divide-y rounded-md rounded-tr-none rounded-br-none text-white/90 bg-white/20 divide-white/20 ">
                  {variables?.map((item, index) =>
                    Object.keys(item, index).map((key, i) => (
                      <div key={i} className="p-1 pr-3">
                        {key}
                      </div>
                    )),
                  )}
                </div>
                {/* Value DIV */}
                <div className=" min-w-[80px]  border divide-y rounded-md rounded-tl-none rounded-bl-none text-white/70 border-white/20 divide-white/20">
                  {variables?.map((item, index) =>
                    Object.values(item, index).map((value, i) => (
                      <div key={i} className="p-1">
                        {value}
                      </div>
                    )),
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center bg-red-300 rounded w-max">
                <h1 className="text-center px-[10px] text-[10px] text-red-700 font-bold">
                  No Records Found!
                </h1>
              </div>
            )}
          </div>
          {/* ADD VARIABLES BUTTOn */}
          <div className="flex items-center h-full gap-2">
            <IconButton onClick={handleOpenVarModal} className="glassed ">
              <PlusCircleIcon className="w-[25px] text-[#FED500] " />
            </IconButton>
            {/* ---------------------DELETE_VAR_BTN----------------------- */}
            <IconButton
              variant="outlined"
              onClick={handleClickOpenVarDialog}
              className="glassed"
            >
              <TrashIcon className="w-[25px] text-black/60 " />
            </IconButton>
          </div>
        </div>
        {/* col-5 ::Created At:: */}
        <div className="border-b  flex flex-col items-center justify-center text-white/80  w-[15vw] border-r border-white/10 p-[10px]">
          <h1 className="text-center">
            {moment.unix(createdAt).format("MMMM Do YYYY h:mm:ss A")}
          </h1>
          {/* <h1 className="w-full mt-5 mb-1 pb-[2px] font-bold text-center border-b border-white/10 text-white/90">
            Relative Time
          </h1>
          <h1 className="text-center">
            {moment(relDate, "YYYYMMDD").fromNow()}
          </h1> */}
        </div>
        {/* col-6 */}
        <div className="border-b w-[6vw] border-r border-white/10 p-[10px] flex items-center justify-center gap-[10px">
          <button
            onClick={handleOpenNotes}
            className="bg-white/20 rounded-xl p-[10px]"
          >
            <PencilAltIcon className="w-[15px] text-white/60 " />
          </button>
        </div>
        {/* col-7 */}
        <div className="border-b w-[15vw] border-r border-white/10 p-[10px] flex items-center justify-center gap-[10px]">
          <button
            onClick={handleOpen}
            className="bg-white/20 rounded-xl p-[10px]"
          >
            <PencilIcon className="w-[15px] text-white/60 " />
          </button>
          <button
            onClick={handleOpenD}
            className="bg-white/20 rounded-xl p-[10px]"
          >
            <TrashIcon className="w-[15px] text-white/60" />
          </button>
        </div>
      </div>

      {/* Update Modal */}
      <EditModal
        open={open}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
        contactData={contactData}
        handleChange={handleChange}
      />

      {/* Delete Modal */}
      <DeleteModal
        openD={openD}
        isDeleted={isDeleted}
        contactData={contactData}
        handleDelete={handleDelete}
        handleCloseD={handleCloseD}
      />

      {/* Notes Model */}
      <NotesModal
        openNotes={openNotes}
        handleCloseNotes={handleCloseNotes}
        number={number}
      />

      {/* Add Var Modal */}
      <AddVariablesModal
        openVarModal={openVarModal}
        handleCloseVarModal={handleCloseVarModal}
        number={number}
      />

      {/* Delete Separate Variables Dialog */}
      <Dialog
        open={openVarDialog}
        onClose={handleCloseVarDialog}

        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", width: 400, borderRadius: "15px" }} className="!rounded-2xl">
          <Toolbar className="!bg-[#FED500] ">
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" className="text-black">
              Delete Variables
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseVarDialog}
              aria-label="close"
              className="text-black"
            >
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={handleCloseVarDialog}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <List>
          {variables?.length > 0 ? (
            <>
              {variables?.map((item, index) => {
                const userKey = Object.keys(item, index).map((key, i) => key);
                const userValue = Object.values(item, index).map(
                  (value, i) => value,
                );
                return (
                  <>
                    <ListItem
                      key={index}
                      button
                      onClick={() => handleDeleteVariable(userKey, userValue)}
                    >
                      <ListItemText primary={userKey} secondary={userValue} />
                    </ListItem>
                    <Divider key={index + 2} />
                  </>
                );
              })}
            </>
          ) : (
            <div className="flex justify-center items-center h-[60px]">
              No data! Please add variables first.
            </div>
          )}
        </List>
      </Dialog>
    </>
  );
};

const ContactTable = ({ contacts }) => {
  // console.log("Contact Data:  ", contacts);
  const [query, setQuery] = useState("");
  const [customAtrKey, setCustomAtrKey] = useState("");
  const [customAtrValue, setCustomAtrValue] = useState("");

  const search = (data) => {
    return data.filter(
      (item) =>
        item?.name?.toLowerCase().includes(query) ||
        item?.number?.toString().toLowerCase().includes(query) ||
        String(moment(item.timestamp).calendar())
          .toLowerCase()
          .includes(query) ||
        item?.variables?.findIndex(
          (obj) =>
            Object.values(obj).findIndex(
              (val) => val.toLowerCase().indexOf(query.toLowerCase()) >= 0,
            ) >= 0,
        ) >= 0,
    );
  };

  const handleFilterByAtr = (e) => {
    return contacts.filter((item) => {
      return item?.variables?.some((atr) => {
        return (
          Object.keys(atr).includes(customAtrKey) &&
          Object.values(atr).includes(customAtrValue)
        );
      });
    });
  };

  return (
    <>
      <div className="flex items-center gap-5  w-full px-[50px] my-[20px]">
        <div className="flex items-center w-[50%]">
          <div className="rounded-tr-none rounded-br-none rounded-lg text-xs bg-white/60 text-black/70 font-bold h-[40px] w-[100px] flex items-center justify-center">
            Search
          </div>
          <input
            type="search"
            name=""
            id=""
            className="w-full h-[40px]  text-black text-xs bg-white/40 focus:bg-white/80 focus:outline-none focus:ring-1 rounded-lg px-2 py-1 placeholder:text-white/80 focus:placeholder:text-gray-500 transition-all duration-300 ease-in-out focus:ring-[#FED500]  rounded-tl-none rounded-bl-none"
            placeholder="Search by Name, Phone Number, Date & Custom Attributes..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {/* Filter Filed */}
        <div className="flex items-center w-[50%]">
          <div className="rounded-tr-none rounded-br-none rounded-lg text-xs bg-white/60 text-black/70 font-bold h-[40px] w-[100px] flex items-center justify-center">
            Filter
          </div>
          <input
            type="search"
            name=""
            id=""
            className="w-1/2 h-[40px]  text-black text-xs bg-white/40 focus:bg-white/80 focus:outline-none focus:ring-1  px-2 py-1 placeholder:text-white/80 focus:placeholder:text-gray-500 transition-all duration-300 ease-in-out focus:ring-[#FED500]"
            placeholder="Enter Custom Attributes Key"
            onChange={(e) => setCustomAtrKey(e.target.value)}
          />
          <input
            type="search"
            name=""
            id=""
            className="w-1/2 h-[40px]  text-black text-xs bg-white/40 focus:bg-white/80 focus:outline-none focus:ring-1  px-2 py-1 placeholder:text-white/80 focus:placeholder:text-gray-500 transition-all duration-300 ease-in-out focus:ring-[#FED500] rounded-lg rounded-tl-none rounded-bl-none "
            placeholder="Enter Custom Attributes Value "
            onChange={(e) => setCustomAtrValue(e.target.value)}
          />
          {/* <form
            onSubmit={handleFilterByAtr}
            className="flex items-center w-full"
          >
            <button
              type="submit"
              className="flex justify-center items-center bg-[#FED500] text-black h-[40px] w-[40px]  rounded-2xl rounded-tl-none rounded-bl-none"
            >
              <SearchIcon className="w-[20px] " />
            </button>
          </form> */}
        </div>
      </div>
      <div className="px-[50px]">
        {customAtrKey.length >= 2 && customAtrValue.length >= 1 ? (
          <div>
            <div className="px-2 py-5 text-white">Showing Filter Result</div>
            <div className="flex text-xs rounded-t-lg glassed bg-white/20">
              <div className="text-start text-white w-[15vw] border-r border-white/10 p-[10px]">
                Name
              </div>
              <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
                Phone Number
              </div>
              <div className="text-center text-white w-[10vw] border-r border-white/10 p-[10px]">
                Status
              </div>
              <div className="flex justify-center  text-white w-[30vw] border-r border-white/10 p-[10px]">
                <h1>Custom Attributes</h1>
              </div>
              <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
                Date
              </div>
              <div className=" text-center text-white w-[6vw] border-r border-white/10 p-[10px]">
                Notes
              </div>
              <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
                Edit/Delete
              </div>
            </div>

            {handleFilterByAtr().map((contact, index) => (
              <ContactList
                key={contact?._id + index}
                id={contact?._id}
                name={contact?.name}
                number={contact?.number}
                createdAt={contact?.created_at}
                username={contact?.username}
                unseen={contact?.unseen}
                unseen_count={contact?.unseen_count}
                variables={contact?.variables}
              />
            ))}
          </div>
        ) : (
          <div className="flex text-xs rounded-t-lg glassed bg-white/20">
            <div className="text-start text-white w-[15vw] border-r border-white/10 p-[10px]">
              Name
            </div>
            <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
              Phone Number
            </div>
            <div className="text-center text-white w-[10vw] border-r border-white/10 p-[10px]">
              Status
            </div>
            <div className="flex justify-center  text-white w-[30vw] border-r border-white/10 p-[10px]">
              <h1>Custom Attributes</h1>
            </div>
            <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
              Date
            </div>
            <div className=" text-center text-white w-[6vw] border-r border-white/10 p-[10px]">
              Notes
            </div>
            <div className="text-center text-white w-[15vw] border-r border-white/10 p-[10px]">
              Edit/Delete
            </div>
          </div>
        )}
        {customAtrKey.length >= 2 && customAtrValue.length >= 1
          ? ""
          : search(contacts).map((contact, index) => (
              <ContactList
                key={contact?._id + index}
                id={contact?._id}
                name={contact?.name}
                number={contact?.number}
                createdAt={contact?.created_at}
                username={contact?.username}
                unseen={contact?.unseen}
                unseen_count={contact?.unseen_count}
                variables={contact?.variables}
              />
            ))}
      </div>
    </>
  );
};

export default ContactTable;
