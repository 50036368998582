import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { axiosInstance } from "../../../utils/axios-instance";

const AllJobs = () => {
  const [jobsData, setJobsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    let data = new FormData();
    data.append("number", "");
    data.append("limit", "100");
    data.append("status", "");
    const result = await axiosInstance.post("filter/alljobs", data);
    setJobsData(result.data);
    setIsLoading(false);
    console.log(jobsData);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div>
        <div className="font-bold text-white">All Jobs</div>
      </div>
    </>
  );
};

export default AllJobs;
