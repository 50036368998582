import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { axiosInstance } from "../../../utils/axios-instance";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const enableAddBtn = () => {
    let enable = false;
    if (name.length > 3 && number.length == 10 && number.length < 11) {
      enable = true;
    } else enable = false;

    return enable;
  };

  useEffect(() => {
    enableAddBtn();
  }, [name, number]);

  const handleAddContact = async () => {
    let data = new FormData();
    data.append("name", name);
    data.append("number", `91${number}`);
    try {
      await axiosInstance.post("contact/add", data);
      setNumber("");
      setName("");
      handleClose();
    } catch (error) {
      console.log("Error in adding contact:: ", error);
    }
  };
  return (
    <>
      <div className="justify-between h-[60px] border-b border-white/10 px-[40px] flex items-center mb-[20px]">
        <div>
          <h1 className="text-white text-lg font-bold">Contacts</h1>
        </div>
        <div>
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            className="bg-[#FED500] rounded px-[10px] py-[5px] text-sm font-bold text-black/80"
          >
            +Add Contact
          </Button>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Contact</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Enter your Name"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="number"
            name="number"
            label="Enter your Phone Number"
            type="text"
            fullWidth
            required
            autoComplete="off"
            variant="standard"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!enableAddBtn()} onClick={handleAddContact}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
