import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { axiosInstance } from "../../../../utils/axios-instance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddVariablesModal = ({ openVarModal, handleCloseVarModal, number }) => {
  const [varList, setVarList] = useState([
    {
      key: "",
      value: "",
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    // console.log("ELEMENT::: ", name)
    // console.log("ELEMENT INDEX::: ", index)
    const list = [...varList];
    list[index][name] = value;
    console.log("Value::: ", list);
    setVarList(list);
  };

  const handleAddVar = () => {
    setVarList([
      ...varList,
      {
        key: "",
        value: "",
      },
    ]);
  };
  const handleRemoveVar = (index) => {
    const list = [...varList];
    list.splice(index, 1);
    setVarList(list);
  };

  // Add variables
  const handleAddVariables = async (e) => {
    e.preventDefault();

    const profileVariables = varList.map((item, index) => {
      const key = item.key;
      const value = item.value;
      return { [key]: value };
    });

    const data = {
      number,
      profile_variables: profileVariables,
    };
    console.log("VAR DATA:: ", data);
    try {
      await axiosInstance.post("add_profile_variables", data);

      setVarList([
        {
          key: "",
          value: "",
        },
      ]);
      handleCloseVarModal();
    } catch (error) {
      console.log("Error in adding custom attributes/Variables:: ", error);
    }
  };

  const isBtnDisable = () => {
    // console.log("Key::", varList[0].key.length > 0)
    // console.log("Value::", varList[0].value.length > 0)
    if (varList[0].key.length > 0 && varList[0].value.length > 0) {
      return true;
    } else return false;
  };

  console.log(isBtnDisable());
  return (
    <Modal
      open={openVarModal}
      // onClose={handleCloseD}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="!rounded-lg  !bg-white/90 !relative">
        <h1 className="text-xl font-black mb-5">
          Add Custom Variables for {number}
        </h1>
        <div className="flex flex-col gap-4 w-full ">
          <form onSubmit={handleAddVariables} className="flex  flex-col gap-2">
            {varList.map((item, index) => (
              <div
                key={index}
                className="flex items-center pl-5 justify-between w-full"
              >
                <div>
                  <TextField
                    id="standard-basic"
                    label="Provide Key"
                    name="key"
                    // value={item.key}
                    onChange={(e) => handleInputChange(e, index)}
                    variant="standard"
                    autoComplete="off"
                  />
                  <TextField
                    id="standard-basic"
                    label="Provide Variables"
                    name="value"
                    // value={item.value}
                    onChange={(e) => handleInputChange(e, index)}
                    variant="standard"
                    autoComplete="off"
                    // className="mt-5"
                  />
                </div>
                <div>
                  {
                    // Condition btn +  and -
                    varList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveVar(index)}
                        className="bg-[#FED500] rounded-full shadow w-[40px] h-[40px] transition-all duration-300 ease-in-out
              transform hover:scale-95 active:scale-90 text-lg font-black mt-3 mr-5"
                      >
                        -
                      </button>
                    )
                  }
                  {
                    // Condition btn +  and -
                    varList.length - 1 === index && (
                      <button
                        type="button"
                        onClick={handleAddVar}
                        className="bg-[#FED500] rounded-full shadow w-[40px] h-[40px] transition-all duration-300 ease-in-out
              transform hover:scale-95 active:scale-90 text-lg font-black mt-3 mr-5"
                      >
                        +
                      </button>
                    )
                  }
                </div>
              </div>
            ))}
            <button
              disabled={!isBtnDisable()}
              type="submit"
              className={`bg-[#FED500] w-full rounded-lg h-[40px] mt-5  ${
                !isBtnDisable() ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              Add Variable
            </button>
          </form>
        </div>
        <button
          onClick={handleCloseVarModal}
          className="absolute -top-[10px] -right-[10px] shadow-md font-black text-black/80 text-sm bg-[#FED500] rounded-full w-[30px] h-[30px]"
        >
          X
        </button>
      </Box>
    </Modal>
  );
};

export default AddVariablesModal;
