import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { useState } from "react";
import { axiosInstance } from "../../../../utils/axios-instance";
import { useEffect } from "react";

const styleNotes = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const NotesModal = ({ openNotes, handleCloseNotes, number }) => {
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notesData, setNotesData] = useState(null);
  const [fetchAgain, setFetchAgain] = useState(false);

  // Add Notes
  const handleAddNotes = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let data = new FormData();
      data.append("notes", notes);
      data.append("number", number);
      await axiosInstance.post("add_notes", data);

      setFetchAgain(true);
      setIsLoading(true);
      setNotes(null);
    } catch (error) {
      console.log("Error in adding the Notes:: ", error);
    }
  };

  // fetch notes
  const fetchNotes = async () => {
    try {
      let data = new FormData();
      data.append("number", number);
      const result = await axiosInstance.post("get_notes", data);
      setNotesData(result.data);
      // console.log(notesData);
      setIsLoading(false);
      setFetchAgain(false);
    } catch (error) {
      console.log("Error in fetching notes:: ", error);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [fetchAgain, openNotes]);

  return (
    <Modal
      open={openNotes}
      // onClose={handleCloseD}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleNotes} className="!rounded-lg  !bg-white/90 !relative p-4">
        <div className="flex flex-col gap-4">
          <form onSubmit={handleAddNotes}>
            <div className="flex items-center justify-between w-full mb-5">
              <h1 className="text-xl font-bold text-gray-700 ">Add Notes</h1>
              <Button
                variant="contained"
                type="submit"
                className="bg-[#FED500] font-bold rounded-lg  text-black/80 shadow"
              >
                Add
              </Button>
            </div>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              maxRows={5}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add your notes..."
              className="w-full h-20 p-4 rounded-lg max-h-40 focus:ring focus:ring-[#FED500] text-sm text-black/80  caret-green-700 focus:outline-none selection:text-green-700 selection:marker:bg-[#FED500]  selection:font-bold"
            />
          </form>
        </div>
        <button
          onClick={handleCloseNotes}
          className="absolute -top-[10px] -right-[10px] shadow-md font-black text-black/80 text-sm bg-[#FED500] rounded-full w-[30px] h-[30px]"
        >
          X
        </button>
        <div className=" max-h-[400px] h-max overflow-y-auto mt-5">
          {isLoading ? (
            <div className="flex items-center justify-center w-full text-white">
              Loading...
            </div>
          ) : (
            <div className="p-4 rounded-md ">
              {notesData !== null ||
              notesData?.notes[0].data.length > 0 ||
              notesData.notes.length !== 0 ? (
                <div className="flex flex-col gap-2">
                  {notesData?.notes.map((item, index) => (
                    <div key={index} className="p-2 border rounded-lg shadow glassed border-black/10">
                      <h1 className="text-xs text-black ">
                        📃{item.data}
                      </h1>
                      <div className="text-[8px] text-black/60">
                        ⌚{notesData.last_updated}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center w-full text-center text-red-700">
                  Not data found! <br /> Please add a note first.
                </div>
              )}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default NotesModal;
