import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeleteModal = ({
  openD,
  isDeleted,
  contactData,
  handleDelete,
  handleCloseD,
}) => {
  return (
    <Modal
      open={openD}
      // onClose={handleCloseD}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="!rounded-lg !bg-white/90 !relative">
        <div className="flex flex-col gap-4">
          {isDeleted ? (
            <div className="text-lg font-bold text-gray-600">
              Contact has been deleted successfully.
            </div>
          ) : (
            <>
              <h1 className="text-lg font-bold text-gray-600">
                Do you want to delete {contactData.number}?
              </h1>
              <button
                onClick={handleDelete}
                className=" bg-[#FED500] rounded px-[10px] py-[5px] text-sm font-bold text-black/80"
              >
                Delete
              </button>
            </>
          )}
        </div>
        <button
          onClick={handleCloseD}
          className="absolute -top-[10px] -right-[10px] shadow-md font-black text-black/80 text-sm bg-[#FED500] rounded-full w-[30px] h-[30px]"
        >
          X
        </button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
