import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../../utils/axios-instance";
import ReactPaginate from "react-paginate";
import ContactTable from "./ContactTable";

function Items({ currentItems, contactData }) {
  // console.log(currentItems.at(-1));
  // console.log(currentItems[0]);

  const selectedrows = contactData.filter((item, index) =>
    currentItems.includes(index + 1),
  );
  return (
    <>
      <ContactTable contacts={selectedrows} />
      <div className="text-center text-[10px] text-white/60 font-bold mt-5">
        Showing data from {currentItems[0]} to {currentItems.at(-1)}
      </div>
      {/* {currentItems &&
        contactData
          .filter((item, index) => currentItems.includes(index + 1))
          .map((contact, index) => {
            return <div>{contact.name}</div>;
          })} */}
    </>
  );
}

const ContactTableNew = ({ itemsPerPage }) => {
  const [contactData, setContactData] = useState([]);
  const [countItems, setCountItems] = useState([]);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const fetchContact = async () => {
    try {
      const response = await axiosInstance.get("contacts");
      if (response.data.contacts) {
        setContactData(response.data.contacts);
        let list = [];
        response.data.contacts.forEach((item, index) => {
          const i = index + 1;
          list.push(i);
        });
        setCountItems(list);
      }
    } catch (error) {
      console.log("Error in Fetching Contacts:: ", error);
    }
  };
  useEffect(() => {
    fetchContact();
  }, []);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = countItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(countItems.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % countItems.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    setItemOffset(newOffset);
  };

  //   console.log(contactData);
  //   console.log(countItems);

  return (
    <>
      <div className="pb-12 mt-5 text-white">
        <div className="text-center text-[10px] text-white/60 font-bold mt-5">
          Showing data from {currentItems[0]} to {currentItems.at(-1)}
        </div>
        <Items currentItems={currentItems} contactData={contactData} />
        <div className="relative flex items-center justify-center w-full mt-5">
          <ReactPaginate
            nextLabel="next >"
            previousLabel="< previous"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            //!--------------------Classes-------------------
            pageClassName="page-item  transition-all "
            pageLinkClassName="page-link  py-2 px-[13px]  transition-all ease-in-out duration-300 glassed hover:bg-[#FED500] hover:text-black rounded-lg "
            previousClassName="page-item" //?-------
            previousLinkClassName=" w-max bg-[#FED500] py-2 rounded-lg px-5 text-black/80 font-bold uppercase transition-all transform ease-in-out hover:scale-110 hover:bg-rose-600 "
            nextClassName="page-item " //?-------
            nextLinkClassName="page-link w-max bg-[#FED500] py-2 rounded-lg px-5  text-black/80 font-bold uppercase transition-all transform ease-in-out hover:scale-110 hover:bg-rose-600 "
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination flex gap-3 w-max border border-white/10 px-2 rounded-xl justify-center items-center  h-[50px]  shadow-lg  text-xs fixed bottom-10 backdrop-blur-xl"
            activeClassName="text-[#FED500] text-base font-black "
            //!--------------------Classes-------------------
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default ContactTableNew;
