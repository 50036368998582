import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import useStyles from "../styles";
import { axiosInstance } from "../../../utils/axios-instance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const statusOptionsArray = [
  "Job Removed",
  "Executed",
  "Paused",
  "Scheduled(Resumed)",
  "Scheduled",
];

const CreateStatus = () => {
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [inputValue, setInputValue] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axiosInstance.post("add_status_fields", {
        status_fields: value,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        className="p-2 px-5 font-semibold bg-[#FED500] text-black rounded-md shadow"
      >
        Create Status
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={style}
            className="rounded-lg bg-white/70 outline-none border-none shadow-md w-[600px]"
          >
            <h1 className="text-2xl font-bold text-gray-800">Create Status</h1>
            <hr className="mb-2" />

            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 item-center pt-5"
            >
              <Autocomplete
                multiple
                id="tags-filled"
                options={statusArr.map((option) => option.title)}
                defaultValue={[statusArr[1].title]}
                freeSolo
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setValue({
                      title: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      title: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                  console.log(value);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Choose or Create a Status"
                    placeholder="Pending"
                  />
                )}
              />
              <Button
                constiant="contained"
                type="submit"
                endIcon={<SendIcon />}
                className=" text-black bg-[#FED500] transform transition-all ease-in-out duration-300 hover:scale-95 mt-5 w-full normal-case"
              >
                Create
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const statusArr = [
  { title: "Open" },
  { title: "Pending" },
  { title: "Solved" },
];

export default CreateStatus;
