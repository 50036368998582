import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { axiosInstance } from "../../utils/axios-instance";
import Autocomplete from "@mui/material/Autocomplete";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Button, TextField } from "@mui/material";
// import { useSelector } from "react-redux";

const AssignNewLeads = () => {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState([]);
  const [number, setNumber] = useState("");
  const [isLeadAssigned, setIsLeadAssigned] = useState(false);
  const [data, setData] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData("");
    setOpen(false);
  };

  const fetchUserName = async () => {
    const response = await axiosInstance.get("get_users");
    console.log(response.data.user_id);
    let list = [];
    response?.data?.user_id.forEach((item) => {
      list.push(item.username);
    });
    setUsername(list);
  };
  useEffect(() => {
    fetchUserName();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    let formData = new FormData();
    formData.append("number", number);
    formData.append("username", data);
    try {
      await axiosInstance.post("admin/assign_user", formData);
      setIsLeadAssigned(true);
      handleClose();
    } catch (error) {
      console.log("Error in Assigning New Leads", error);
    } finally {
      setTimeout(() => {
        setIsLeadAssigned(false);
      }, 6000);
    }
  };
  return (
    <div className="rounded-lg w-[50%] drop-shadow-lg bg-white flex flex-col gap-4 p-5 mt-5">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isLeadAssigned}
        autoHideDuration={6000}
        onClose={() => setIsLeadAssigned(false)}
      >
        <Alert onClose={() => setIsLeadAssigned(false)} severity="success">
          New Lead assigned successfully!
        </Alert>
      </Snackbar>

      <h1>Assign user from message queue</h1>

      <form onSubmit={handleSubmit} className="flex flex-col w-full gap-5">
        <TextField
          id="outlined-basic"
          label="Number"
          variant="outlined"
          size="small"
          fullWidth
          name="number"
          value={number}
          helperText="Number you want to assign."
          onChange={(e) => setNumber(e.target.value)}
        />
        <Autocomplete
          disablePortal
          clearIcon=""
          disableClearable={true}
          id="combo-box-demo"
          options={username}
          fullWidth
          //   sx={{ width: 200 }}
          value={data}
          onChange={async (event, newValue) => {
            setData(newValue);
            handleClickOpen();
          }}
          className="!text-xs"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Username"
              // fullWidth
              size="small"
              className="!text-xs"
              helperText="Enter the username to which you want to assign this number."
            />
          )}
        />
        <Button
          variant="contained"
          type="submit"
          className="rounded-lg bg-appPurple-400 text-white"
        >
          Assign User
        </Button>
      </form>
    </div>
  );
};

export default AssignNewLeads;
