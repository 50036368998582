import React from "react";
import QueryJobs from "./modals/QueryJobs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AllJobs from "./components/AllJobs";
import ScheduledJobs from "./components/ScheduledJobs";
import ScheduledResumedJobs from "./components/ScheduledResumedJobs";
import PausedJobs from "./components/PausedJobs";
import JobRemoved from "./components/JobRemoved";
import ExecutedJobs from "./components/ExecutedJobs";
import CreateStatus from "./modals/CreateStatus";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Jobs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {/* Nabvar */}
      <div className="flex  justify-between px-[30px] items-center h-[80px] border-b border-white border-opacity-20 shadow-lg w-full ">
        <h1 className="text-lg font-semibold text-white/80">Jobs Sequence</h1>
        <div className="flex gap-4">
          <CreateStatus />
          <QueryJobs />
        </div>
      </div>
      {/* Body-container */}
      <div>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "rgba(225, 225, 225, 0.1)",
              color: "rgba(225, 225, 225, 0.8)",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              textColor="rgba(225, 225, 225, 0.2)"
              indicatorColor="primary" //#FED500
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Scheduled" {...a11yProps(1)} />
              <Tab label="Scheduled(Resumed)" {...a11yProps(2)} />
              <Tab label="Paused" {...a11yProps(3)} />
              <Tab label="Job Removed" {...a11yProps(4)} />
              <Tab label="Executed" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AllJobs />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ScheduledJobs />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ScheduledResumedJobs />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PausedJobs />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <JobRemoved />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ExecutedJobs />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Jobs;
