import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../../utils/axios-instance";
import { timeConverter } from "../../../../utils/date-parse";
import InfiniteScroll from "react-infinite-scroll-component";

const UnreadChat = ({ onClickItem }) => {
  //? States--->
  const [hasMore, setHasMore] = useState(true);
  const [totalOpenChats, setTotalOpenChats] = useState(0);
  const [unreadChatData, setUnreadChatData] = useState([]);
  const [loadedOpenChats, setLoadedOpenChats] = useState(15);

  //fetch unread chats
  const fetchUnreadChats = async () => {
    const data = new FormData();
    data.append("size", `${loadedOpenChats}`);
    data.append("start", "0");
    data.append("status", "");
    data.append("unseen", "1");
    try {
      const result = await axiosInstance.post("v2/contact_status", data);
      if (result.data.total_count === unreadChatData.length) {
        setHasMore(false);
        return;
      }
      console.log(result.data)
      setUnreadChatData(result.data?.contacts);
      setTotalOpenChats(result.data?.total_count);
      setLoadedOpenChats(loadedOpenChats + 15);
    } catch (error) {
      console.log("Error in fetching Open Chat History", error);
    }
  };

  useEffect(() => {
    fetchUnreadChats();
  }, []);

  return (
    <InfiniteScroll
      dataLength={unreadChatData.length}
      next={fetchUnreadChats}
      hasMore={hasMore}
      loader={<h4>{`${unreadChatData.length}/${totalOpenChats} Chats`}</h4>}
      height={600}
      scrollThreshold={"500px"}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {unreadChatData?.map((item, index) => {
        const id = item._id;
        const name = item.name;
        const number = item.number;
        const contactUsername = item.username;
        const unseenCount = item.unseen_count;
        const time = item.timestamp;
        return (
          <div key={index}>
            <div
              key={id}
              className="group p-[5px] my-[5px] flex items-center w-full rounded cursor-pointer hover:bg-appGray-200"
              onClick={() => onClickItem(id, name, number, contactUsername)}
            >
              {/* User Image Container */}
              <div>
                <div className="w-[40px] h-[40px] bg-appPurple-200 group-hover:bg-appPurple-400 rounded-full flex justify-center items-center text-white text-opacity-80">
                  {!name ? "?" : name.toUpperCase()[0]}
                </div>
              </div>
              {/* User Image Container ENDS-- */}
              <div className="mt-[-5px] pl-4 w-full text-black text-opacity-80">
                <div className="flex justify-between pr-[10px]">
                  <h1 className="text-xs font-bold group-hover:text-white/95">
                    {!name ? "User" : name}
                  </h1>
                  <div className="">
                    <p className="text-[10px] text-gray-500 group-hover:text-white/60 ">
                      {timeConverter(time)}
                    </p>
                  </div>
                </div>
                <div className="flex w-full justify-between pr-[10px]">
                  <p className="text-[10px] text-gray-500 group-hover:text-white/60">
                    {number}
                  </p>
                  {unseenCount === 0 ? (
                    ""
                  ) : (
                    <div className="bg-red-500 h-[20px] w-[20px] text-red-600 font-bold bg-opacity-20 rounded-full flex justify-center items-center text-xs">
                      {unseenCount}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </InfiniteScroll>
  );
};

export default UnreadChat;
