import React from "react";
import AddSequenceModal from "./components/Modal/AddSequenceModal";
// Headless UI
import { Tab } from "@headlessui/react";
// Modals Components
import SendSequenceModal from "./components/Modal/SendSequenceModal";
import SequenceTabOne from "./components/SequenceTabOne";
import JobsTabTwo from "./components/Table/JobsTabTwo";
import SearchSequenceModal from "./components/Modal/SearchSequenceModal";

const Sequence = () => {
  return (
    <div>
      {/* Nabvar */}
      <div className="flex  justify-between px-[30px] items-center h-[80px] border-b border-white border-opacity-20 shadow-lg w-full ">
        <SearchSequenceModal />
        <div className="flex gap-4">
          <SendSequenceModal />
          <AddSequenceModal />
        </div>
      </div>

      {/* Show data */}
      <div className="p-5 overflow-y-auto h-[85vh]">
        <SequenceTabOne />
      </div>
    </div>
  );
};

export default Sequence;
