import React from "react";
import Backdrop from "@mui/material/Backdrop";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Drawer from "@mui/material/Drawer";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import Typography from "@mui/material/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Box, TextField, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useState } from "react";
import { axiosInstance } from "../../../utils/axios-instance";
import axios from "axios";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { useEffect } from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChangeLead from "../../../components/admin/ChangeLead";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserHead = ({ name, number, timestamp, chatDisabled, username }) => {
  const templateData = useSelector((state) => state.broadcast.templateMessages);
  const [notes, setNotes] = useState("");
  const [fetchNotesOpen, setFetchNotesOpen] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isLoadingN, setIsLoadingN] = useState(true);
  const [notesData, setNotesData] = useState(null);
  const [fetchAgain, setFetchAgain] = useState(false);

  const toggleDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    if (state.right === true) {
      setFetchNotesOpen(true);
    }
  };
  const [statusData, setStatusData] = useState(null);
  const [CurrStatusData, setCurrStatusData] = useState(null);
  const [currStatusChanged, setCurrStatusChanged] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => {
    setFetchNotesOpen(true);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openStatus = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  // console.log(chatDisabled);

  const data = templateData?.waba_templates ? templateData?.waba_templates : [];
  const onChangeTemplate = (e, newValue) => {
    console.log("tttttttttttttt:::", e, " vvvvvv::::::::::", newValue);
    setSelectedTemplate(newValue);
  };

  const handleResumeSession = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      // broadcast_name: "none",
      language: String(selectedTemplate.language),
      namespace: String(selectedTemplate.namespace),
      template_name: String(selectedTemplate.name),
      to: Number(number),
    };
    const token = localStorage.getItem("id_token");
    try {
      await axios.post("https://api.notbot.in/v1/send_template", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  //!Notes Logic---->

  // Add Notes
  const handleAddNotes = async (e) => {
    e.preventDefault();
    setIsLoadingN(true);
    try {
      if (notes.length > 0) {
        let data = new FormData();
        data.append("notes", notes);
        data.append("number", number);
        await axiosInstance.post("add_notes", data);
      } else {
        alert("Empty file!");
      }

      setFetchAgain(true);
      setIsLoadingN(false);
      setNotes(null);
    } catch (error) {
      console.log("Error in adding the Notes:: ", error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let d = new FormData();
      d.append("number", number);
      const result = await axiosInstance.post("get_notes", d);
      setNotesData(result.data);
      setFetchAgain(false);
      setFetchNotesOpen(false);
    };
    fetch();
  }, [fetchAgain, fetchNotesOpen, number]);

  useEffect(() => {
    fetchSelectedNumberStatus();
  }, [number]);

  //!Notes Logic---->END<----->

  // Fetch get_profile_variables
  const fetchStatus = async () => {
    // let fdata = new FormData();
    // fdata.append("number", number);
    try {
      const result = await axiosInstance.get("get_status_fields");
      setStatusData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStatus();
    fetchSelectedNumberStatus();
  }, [currStatusChanged, number]);

  // console.log(statusData)

  const options = statusData?.status_fields;

  const ITEM_HEIGHT = 48;

  //!Add Status
  const handleAddStatus = async (selectedStatus) => {
    console.log(selectedStatus);
    let addStatusData = new FormData();
    addStatusData.append("number", number);
    addStatusData.append("status", selectedStatus);
    try {
      const result = await axiosInstance.post("update_status", addStatusData);
      setCurrStatusChanged(true);
    } catch (error) {
      console.log(error);
    }
    handleCloseStatus();
  };
  // !Fetch curr status
  const fetchSelectedNumberStatus = async () => {
    let getStatusData = new FormData();
    getStatusData.append("number", number);
    try {
      const result = await axiosInstance.post("get_status", getStatusData);
      setCurrStatusData(result.data);
      setCurrStatusChanged(false);
    } catch (error) {
      console.log(error);
    }
  };
  const list = (anchor) => (
    <Box
      // sx={{ width: 400px}}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      className="w-[400px] bg-appGray-700 h-screen px-8 py-5"
    >
      <h1 className="pb-1 text-xl font-bold border-b text-black/90 border-white/10">
        Actions Center
      </h1>

      <section className="flex flex-col w-full p-4 mt-5 rounded-xl bg-appGray-500 drop-shadow-xl">
        <TextareaAutosize
          aria-label="minimum height"
          // minRows={}
          maxRows={2}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add your notes..."
          className="w-full  p-2 rounded-lg  focus:ring focus:ring-[#FED500]  text-black/80  caret-green-700 focus:outline-none selection:text-green-700 selection:marker:bg-[#FED500]  selection:font-bold"
        />
        <div className="flex justify-end w-full gap-2 mt-2 h-max">
          {/* <Button className="!w-[30px] !h-[30px] rounded-full  bg-red-200 text-red-600">
            <DeleteForeverRoundedIcon />
          </Button> */}
          <Button
            onClick={handleAddNotes}
            className="!w-[30px] !h-[30px] rounded-full  bg-green-200 text-green-600 "
          >
            <AddRoundedIcon />
          </Button>
        </div>
        {/* -------------------Show Notes--------------- */}
        <div className=" max-h-[400px] h-max overflow-y-auto mt-5">
          {isLoading ? (
            <div className="flex items-center justify-center w-full text-black">
              Loading...
            </div>
          ) : (
            <div className="p-4 rounded-md bg-appPurple-300">
              {notesData !== null ? (
                <div>
                  {notesData?.notes.map((item, index) => (
                    <div key={index}>
                      <h1 className="text-black">📃{item.data}</h1>
                      <div className="mt-5 text-xs text-black/60">
                        ⌚{notesData.last_updated}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center w-full text-center text-red-700">
                  Not data found! <br /> Please add a note first.
                </div>
              )}
            </div>
          )}
        </div>
        {/* ---------------------Leads----Section----------------------- */}
        {/* ---------------------Leads----Section----------------------- */}
        {/* ---------------------Leads----Section----------------------- */}
        {/* ---------------------Leads----Section----------------------- */}
      </section>

      <section className="mt-5">
        <ChangeLead />
      </section>
    </Box>
  );

  return (
    <div className="flex  items-center justify-between  w-full h-[80px] px-[20px] text-black text-opacity-80 font-[24px]   shadow-xl border-b border-white border-opacity-10 bg-white">
      <div className="flex items-center gap-2  flex-1">
        <div className="w-[30px] h-[30px] bg-appPurple-400 rounded-full flex justify-center items-center text-white text-opacity-80">
          {number ? name.toString()[0] : "R"}
        </div>
        <div className="flex-1 flex items-center gap-2">
          <div>
            <div className={"flex justify-start items-center   "}>
              <h1 className={"font-bold text-xs"}>
                {number ? name : "Recipient name"}
              </h1>
              {!chatDisabled && (
                <div className="w-[10px] h-[10px] bg-green-500 rounded-full ml-[10px]" />
              )}
            </div>
            {timestamp &&
              (chatDisabled ? (
                <p className={"text-[10px]"}>Your chat is disabled</p>
              ) : (
                <>
                  <p
                    className={"text-[10px]"}
                  >{`Your chat will be active till ${timestamp}`}</p>
                </>
              ))}
          </div>
          <ChangeLead name={name} number={number} oldUsername={username} />
        </div>
      </div>

      <div className="flex items-center gap-2">
        {/* Button to resume chat session */}
        {timestamp && chatDisabled ? (
          <>
            <Button
              onClick={handleOpen}
              className="rounded px-[10px] py-[5px] bg-[#FED500] text-black/80 font-bold  text-xs"
            >
              Resume Session
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <div className="w-[500px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded p-10">
                    <Typography
                      size={"sm"}
                      className="mb-3 text-lg font-bold text-black "
                    >
                      Enter phone number
                    </Typography>
                    <form onSubmit={handleResumeSession}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Number"
                        value={number}
                        variant="outlined"
                        className="w-full mt-5 mb-2"
                      />
                      <br />
                      <Autocomplete
                        id="combo-box-demo"
                        options={data}
                        size={"small"}
                        onChange={onChangeTemplate}
                        getOptionLabel={(option) => option.name.toString()}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Template"
                            variant="outlined"
                          />
                        )}
                      />
                      <div className="flex justify-end w-full">
                        <button
                          type="submit"
                          className="rounded px-[10px] py-[5px] bg-[#FED500] text-black/80 font-bold mt-5"
                        >
                          {isLoading ? (
                            <CircularProgress
                              style={{ width: "15px", height: "15px" }}
                              className="!text-black "
                            />
                          ) : (
                            "Resume Session"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </>
        ) : (
          <div className="flex justify-end items-center gap-2 ">
            {/* Drawer */}
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* --------------------Action------------------------ */}
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  className="px-2 text-xs text-white bg-appPurple-300 rounded-lg"
                >
                  Actions
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        )}
        {/* -------------------Show curr status--------------------- */}
        <div className="text-xs capitalize font-bold ml-2">
          {CurrStatusData !== null && CurrStatusData?.status.length > 0
            ? CurrStatusData?.status
            : "Open"}
        </div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openStatus ? "long-menu" : undefined}
          aria-expanded={openStatus ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          className=" text-appPurple-300 "
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openStatus}
          onClose={handleCloseStatus}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options?.map((option) => (
            <MenuItem
              key={option}
              // selected={option === "Pyxis"}
              onClick={() => handleAddStatus(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default UserHead;
