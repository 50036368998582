import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditModal = ({
  open,
  handleClose,
  handleUpdate,
  contactData,
  handleChange,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleUpdate}>
          <div className="flex flex-col w-full gap-8">
            <TextField
              id="standard-basic"
              label="Name"
              name="name"
              value={contactData.name}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              id="standard-basic"
              label="Phone"
              name="number"
              value={contactData.number}
              onChange={handleChange}
              variant="standard"
            />
            <button
              type="submit"
              className="bg-[#FED500] rounded px-[10px] py-[5px] text-sm font-bold text-black/80"
            >
              Update Contact
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditModal;
